import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_93JESmyYJVxeMeta } from "/opt/build/repo/pages/project/[slug].vue?macro=true";
import { default as indexnpobEWMjMtMeta } from "/opt/build/repo/pages/project/index.vue?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___en",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___fr",
    path: aboutlv6i1FL0CfMeta?.path ?? "/fr/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact___en",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact___fr",
    path: contactNfojXvLlAkMeta?.path ?? "/fr/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___fr",
    path: indexlaFeZuYZkUMeta?.path ?? "/fr",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JESmyYJVxeMeta?.name ?? "project-slug___en",
    path: _91slug_93JESmyYJVxeMeta?.path ?? "/project/:slug()",
    meta: _91slug_93JESmyYJVxeMeta || {},
    alias: _91slug_93JESmyYJVxeMeta?.alias || [],
    redirect: _91slug_93JESmyYJVxeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/project/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JESmyYJVxeMeta?.name ?? "project-slug___fr",
    path: _91slug_93JESmyYJVxeMeta?.path ?? "/fr/project/:slug()",
    meta: _91slug_93JESmyYJVxeMeta || {},
    alias: _91slug_93JESmyYJVxeMeta?.alias || [],
    redirect: _91slug_93JESmyYJVxeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/project/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnpobEWMjMtMeta?.name ?? "project___en",
    path: indexnpobEWMjMtMeta?.path ?? "/project",
    meta: indexnpobEWMjMtMeta || {},
    alias: indexnpobEWMjMtMeta?.alias || [],
    redirect: indexnpobEWMjMtMeta?.redirect,
    component: () => import("/opt/build/repo/pages/project/index.vue").then(m => m.default || m)
  },
  {
    name: indexnpobEWMjMtMeta?.name ?? "project___fr",
    path: indexnpobEWMjMtMeta?.path ?? "/fr/project",
    meta: indexnpobEWMjMtMeta || {},
    alias: indexnpobEWMjMtMeta?.alias || [],
    redirect: indexnpobEWMjMtMeta?.redirect,
    component: () => import("/opt/build/repo/pages/project/index.vue").then(m => m.default || m)
  }
]